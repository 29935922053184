import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import gsap from 'gsap'

/**
 * Base
 */
// Debug
const gui = new GUI()
gui.hide()
const debugObject = {}

window.addEventListener('keydown', (event) =>{
    if (event.key == ']'){
        if (gui._hidden){
            gui.show()
        } else {
            gui.hide()
        }
    }
})

// Canvas
const canvas = document.querySelector('canvas.webgl')

// HTML Layer
const main = document.querySelector('main')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('textures/matcaps/7.png')
matcapTexture.colorSpace = THREE.SRGBColorSpace

const gradientTexture = textureLoader.load('textures/gradients/5.jpg')
gradientTexture.colorSpace = THREE.SRGBColorSpace

const fontLoader = new FontLoader()

const donuts = []

fontLoader.load('/fonts/helvetiker_bold.typeface.json', (font) => {
    const textGeometry = new TextGeometry(
        '2Layers',
        {
            font: font,
            size: .5,
            height: .2,
            curveSegments: 4,
            bevelEnabled:true,
            bevelThickness: .03,
            bevelSize: .02,
            bevelOffset: .0,
            bevelSegments: 4
        }
    )
    // textGeometry.computeBoundingBox()
    // // textGeometry.translate(
    // //     -(textGeometry.boundingBox.max.x -.02 ) / 2,
    // //     -(textGeometry.boundingBox.max.y-.02) / 2,
    // //     -(textGeometry.boundingBox.max.z-.03) / 2
    // //     )

    textGeometry.center()
    // const material = new THREE.MeshMatcapMaterial({ wireframe: false})
    
    const material = new THREE.MeshToonMaterial()
    gradientTexture.magFilter = THREE.NearestFilter
    gradientTexture.minFilter = THREE.NearestFilter
    gradientTexture.generateMipmaps = false
    material.gradientMap = gradientTexture


    // material.matcap = gradientTexture
    const text = new THREE.Mesh(textGeometry, material)
    scene.add(text)


    const donutGeometry = new THREE.TorusGeometry(.3, .2, 20, 45)

    for(let i = 0; i < 200; i++) {
        const donut = new THREE.Mesh(donutGeometry, material)
        
        donut.position.x = (Math.random() - .5) * 15
        donut.position.y = (Math.random() - .5) * 15
        donut.position.z = (Math.random() - .5) * 15


        donut.rotation.x = Math.random() * Math.PI
        donut.rotation.y = Math.random() * Math.PI

        donut.scale.set(0, 0, 0)

        donuts.push(donut)

        scene.add(donut)
    }
})


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.set(-2.5, 0, 0);
scene.add(camera)


const cameraFolder = gui.addFolder('Camera');
cameraFolder.add(camera.position, 'x', -10, 10).listen();
cameraFolder.add(camera.position, 'y', -10, 10).listen();
cameraFolder.add(camera.position, 'z', -10, 10).listen();
cameraFolder.add(camera.rotation, 'x', -Math.PI, Math.PI).listen();
cameraFolder.add(camera.rotation, 'y', -Math.PI, Math.PI).listen();
cameraFolder.add(camera.rotation, 'z', -Math.PI, Math.PI).listen();
cameraFolder.open();


const pointlight = new THREE.PointLight(0xffffff, 300)
pointlight.position.x = -6
pointlight.position.y = 16
pointlight.position.z = 10

scene.add(pointlight)

function oscillateCamera() {
    const tl = gsap.timeline({
      repeat: -1, // Repeat indefinitely
      repeatDelay: 3,
      delay: 3,
      yoyo: true // Go back and forth between the animations
    });

    // Start both animations at the same time
    tl.to(camera.position, {
      duration: 5,
      x: -1.5,
      y: -0.75,
      z: 7.75,
      ease: 'power2.inOut'
    }, 'start') // Label this animation as 'start'
    .to(pointlight.position, {
      duration: 5,
      x: 8,
      y: -8,
      z: -4,
      ease: 'power2.inOut'
    }, 'start'); // Start this animation at the same time as the one labeled 'start'
}


window.onload = () => {
  gsap.to(camera.position, {
      duration: 3, // Snappier movement to first position
      x: 4.25,
      y: -5,
      z: 10,
      ease: 'power2.inOut'
  });
  oscillateCamera();
};



main.addEventListener('click', () => {


  // Animate donuts on each click without affecting the camera oscillation
  donuts.forEach((donut) => {
    const scale = Math.random();
    const rotation = Math.random() * Math.PI;
    gsap.to(donut.scale, {x: scale, y: scale, z: scale, duration: 3, delay: 0.5});
    gsap.to(donut.rotation, {x: rotation, y: rotation, duration: 2, delay: 0.5});
  
    gsap.to(donut.position, {x: (Math.random() - .5) * 15, y: (Math.random() - .5) * 15, z: (Math.random() - .5) * 15, duration: 3, delay: 0, ease: 'power2.inOut',
    })
  });
});


// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


// Lights
// const ambientLight = new THREE.AmbientLight(0xFFFFFF, 1)
// scene.add(ambientLight)


/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()